<template>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<v-container fluid>
</v-container>
</div>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
export default {
  name: 'HomeView',
  data: () => ({
      items: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        }
      ],
      fluid: true
    }),
  components: { BreadcrumbComponent },
}
</script>

<style type="text/css">
  
</style>